import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { TextInput } from "clutch/src/TextInput/TextInput.js";

import { readState } from "@/__main__/app-state.mjs";
import router, { updateRoute } from "@/__main__/router.mjs";
import {
  APP_SCROLLER,
  GAME_BOX_ICONS,
  GAME_COLORS,
  GAME_ICON_SHAPES,
  GAME_NAME_MAP,
} from "@/app/constants.mjs";
import type { GameSymbol } from "@/app/games.mjs";
import CircleClose from "@/inline-assets/circle-close.svg";
import ButtonInfo from "@/inline-assets/info-button.svg";
import Link from "@/inline-assets/link.svg";
import { addRecentResult, setSearchQuery } from "@/search/actions.mjs";
import { CACHE_KEY } from "@/search/constants.mjs";
import { GAME_SEARCH } from "@/search/fetch-search-data.mjs";
import {
  dispatchSearch,
  getResults,
  setupSearchVectors,
} from "@/search/modal-view.mjs";
import searchRefs from "@/search/refs.mjs";
import SearchBar from "@/search/SearchBar.jsx";
import {
  cssOrder,
  SearchPageContainer,
  SearchPageHeader,
} from "@/search/SearchPage.style.jsx";
import type {
  SearchFilter,
  SearchResult,
  SearchResultData,
} from "@/search/types.d.mjs";
import { decodeQuery, writeClipboard } from "@/search/util.mjs";
import { GameFilterBar } from "@/shared/GameFilterBar.jsx";
import Ripple from "@/shared/Ripple.jsx";
import { classNames } from "@/util/class-names.mjs";
import { devError, devWarn } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import { removeFromArray, renderText } from "@/util/helpers.mjs";
import optionalMerge from "@/util/optional-merge.mjs";
import useCallbackRef from "@/util/use-callback-ref.mjs";
import { useClientLayoutEffect } from "@/util/use-client-layout-effect.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const categoryFilter: SearchFilter = {
  autoFn: (result: SearchResult) => result.label?.[0] as string,
  autoLabel: (result: SearchResult) => result.label,
  accessor: (result: SearchResult) => result.label?.[0] as string,
  label: ["search:category", "Category"] as const,
};

const searchParamKey = "q";

const additionalFiltersNotice: SearchFilter = {
  label: ["search:additionalFilters", "Additional Filters"] as const,
  omitPrefix: true,
  Component: () => {
    const { t } = useTranslation();
    return (
      <div className={`flex gap-sp-2 shade3 ${cssOrder(1)}`}>
        <ButtonInfo />
        <span className="type-caption--semi">
          {t(
            "search:additionalFiltersNotice",
            "Select a game for additional filters",
          )}
        </span>
      </div>
    );
  },
  order: 1,
};

const defaultFilters = [categoryFilter, additionalFiltersNotice];

const fullBleed = false;

function SearchPage() {
  const { globalSearchQuery } = useSnapshot(searchRefs);

  const {
    search: { recents, favorites },
  } = useSnapshot(readState);

  const [isNavigating, setIsNavigating] = useState(true);

  useEffect(() => {
    setSearchQuery(router.route.searchParams?.get(searchParamKey) || "");
    setIsNavigating(false);
  }, []);

  // update q when global search query changes
  useClientLayoutEffect(() => {
    if (isNavigating) return;
    // we construct new params and call updateRoute to update url immediately
    const searchParams = new URLSearchParams(router.route.searchParams);
    if (globalSearchQuery) searchParams.set(searchParamKey, globalSearchQuery);
    else searchParams.delete(searchParamKey);
    updateRoute(router.route.currentPath, searchParams, undefined, true);
  }, [globalSearchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  // in general, this page is designed to use the global search query, and the
  // url is only for display and navigation persistence (eg. user refreshses)
  // however, on initial load we should use the url since the global search query
  // will not be set during the intiial load
  const query = decodeQuery(
    router.route.state.isUpdate
      ? globalSearchQuery
      : router.route.searchParams?.get(searchParamKey) || "",
  );

  const [filter, setFilter] = useState({
    game: null as GameSymbol,
  });
  const [activeFilters, setActiveFilters] = useState(
    new Map<SearchFilter, string[]>(),
  );

  const { t } = useTranslation();

  const [isLoading, setIsLoadingResults] = useState(true);
  const [resultsData, setResultsData] = useState([] as SearchResultData);

  const availableFilters = useMemo(() => {
    const filterBases = filter.game
      ? GAME_SEARCH[filter.game]?.filters || []
      : defaultFilters;

    const filterDataEntries: [SearchFilter, [string, number][]][] = [];
    for (const filterBase of filterBases) {
      // for display-only filters
      if (!filterBase.accessor) {
        filterDataEntries.push([filterBase, null]);
        continue;
      }
      const filterData: Record<string, number> = {};
      if (filterBase.autoFn) filterBase.options = {};
      for (const resultGroup of resultsData) {
        if (filterBase.autoFn) {
          const label = resultGroup.label;
          if (!label?.[0]) {
            devError("search result group is missing label", {
              resultGroup,
              query: resultsData.query,
              activeFilters: [...activeFilters.entries()].map(([f, v]) => [
                f.label,
                v,
              ]),
            });
          }
          filterBase.options[label[0] as string] = {
            label,
          };
        }

        for (const result of resultGroup) {
          const resultMatch = filterBase.accessor(result);

          if (!resultMatch) continue;

          if (!filterBase.options?.[resultMatch] && !filterBase.autoFn)
            continue;

          if (filterBase.autoFn) {
            filterBase.options[filterBase.autoFn(result) as string] = {
              label: filterBase.autoLabel(result),
            };
          }

          filterData[resultMatch] ??= 0;
          filterData[resultMatch]++;
        }
      }
      const filterOptionValues = Object.entries(filterData);

      if (filterBase.options && !filterOptionValues.length) continue;
      if (!filterBase.autoFn) {
        // except with autoOptions (which are ordered by insertion)
        // boost 0 values so active filters with 0 results are still shown
        optionalMerge(
          filterData,
          Object.fromEntries(
            activeFilters.get(filterBase)?.map((v) => [v, 0]) || [],
          ),
        );
        filterOptionValues.sort((a, b) => boostZero(b[1]) - boostZero(a[1]));
      }
      filterDataEntries.push([filterBase, filterOptionValues]);
    }

    return filterDataEntries;
  }, [filter.game, resultsData, activeFilters]);

  // handle fetching new results when query or filters change
  useEffect(() => {
    (async () => {
      await setupSearchVectors();
      if (!query) setIsLoadingResults(false);

      const res = getResults(query, (r) => {
        if (!r) return false;
        if (r.game === filter.game || !filter.game) {
          return true;
        }
        return false;
      });

      setResultsData(res);

      dispatchSearch({ query, game: filter.game }, (results) => {
        setResultsData((prev) => {
          if (prev.query !== results.query) return prev;
          setIsLoadingResults(false);
          if (!results.length) return prev;
          // merge fn
          return Object.assign([...prev, results], {
            ...prev,
            total: prev.total + results.length,
          });
        });
      });
    })();
  }, [query, filter.game]);

  // clear filters when game changes
  useEffect(() => {
    setActiveFilters(new Map());
  }, [filter.game]);

  // handle updating empty results when recents or favorites change
  useEffect(() => {
    if (query) return;
    // data for recents and favorites
    const res = getResults(query, (r) => {
      if (!r) return false;
      if (r.game === filter.game || !filter.game) {
        return true;
      }
      return false;
    });
    setResultsData(res);
  }, [query, filter.game, recents, favorites]);

  const handleClearFilters = useCallback(() => {
    setActiveFilters(new Map());
    setFilter({ game: null });
  }, []);

  const noResults = useMemo(() => {
    return !isLoading && !resultsData.length;
  }, [isLoading, resultsData]);

  const filteredResults = useMemo(() => {
    const filteredResults: typeof resultsData = Object.assign([], resultsData, {
      length: 0,
    });
    for (const resultGroup of resultsData) {
      const filteredResultGroup: typeof resultGroup = Object.assign(
        [],
        resultGroup,
        { length: 0 },
      );
      for (const result of resultGroup) {
        let passes = true;
        for (const [filter, values] of activeFilters.entries()) {
          const resultValue = filter?.accessor?.(result);
          if (!resultValue || !values.includes(resultValue)) {
            passes = false;
            break;
          }
        }
        if (passes) filteredResultGroup.push(result);
      }
      if (!filteredResultGroup.length) continue;
      filteredResults.push(filteredResultGroup);
    }
    return filteredResults;
  }, [resultsData, activeFilters]);

  const allResultsFiltered = useMemo(() => {
    return !isLoading && resultsData.length && !filteredResults.length;
  }, [isLoading, resultsData, filteredResults]);

  const containerRef = useCallbackRef((_node: HTMLElement) => {
    const [scroller] = globals.document.getElementsByClassName(APP_SCROLLER);

    /** this is all just a way to keep the scroll bar on the scroller
        while masking the results list which is longer than the scroller,
        since there is no way to mask-attach to the scroller */
    let frame;
    // on scroll only fires on scroll INPUT, but not on interpolation (smooth scroll)
    const onScroll = () => {
      if (frame) return;
      const elementsToMask = scroller.getElementsByClassName("mask-scroll");
      // so we must use requestAnimationFrame to get the interpolated scroll
      const animationLoop = () => {
        const { scrollTop } = scroller;
        // we must set the mask-image property directly, since setting to a variable
        // will force reflow
        // in this way we guarantee to the renderer that there is no change in layout
        const mask = `linear-gradient(
            to bottom,
            transparent ${scrollTop}px,
            black calc(${scrollTop}px + var(--sp-8))
          )`;
        for (const element of elementsToMask) {
          if (!(element instanceof HTMLElement)) continue;
          // the webkit variant IS needed for webkit browsers
          element.style.maskImage = mask;
          element.style.webkitMaskImage = mask;
        }
        // cancels if no longer scrolling (frame unset)
        frame &&= requestAnimationFrame(animationLoop);
      };
      animationLoop();
    };
    const onScrollEnd = () => {
      cancelAnimationFrame(frame);
      frame = null;
    };
    scroller.addEventListener("scroll", onScroll);
    scroller.addEventListener("scrollend", onScrollEnd);

    return () => {
      scroller.removeEventListener("scroll", onScroll);
      scroller.removeEventListener("scrollend", onScrollEnd);
    };
  }, []);

  useEffect(() => {
    addEventListener("keydown", keyDownHandler);

    return () => {
      removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  // For handling sticky sidebar + scrolling.
  const sidebarRef = useCallbackRef((sidebar: HTMLElement) => {
    if (!sidebar) return;
    const [root] = globals.document.getElementsByClassName(APP_SCROLLER);
    if (!(root instanceof HTMLElement)) return;

    const updateOffset = () => {
      const offset = root.offsetHeight - sidebar.offsetHeight;
      sidebar.style.setProperty(
        "--y-offset",
        `min(var(--content-start), ${offset}px)`,
      );
    };
    const obs =
      typeof ResizeObserver !== "undefined"
        ? new ResizeObserver(updateOffset)
        : null;
    obs?.observe(root);
    obs?.observe(sidebar);
    updateOffset();
    return () => obs?.disconnect();
  }, []);

  return (
    <SearchPageContainer
      ref={containerRef}
      {...classNames(
        fullBleed && "full-bleed",
        isNavigating && "is-navigating",
      )}
    >
      <SearchPageHeader className="search-header">
        <SearchBar
          onChange={(e) => {
            const value = e.currentTarget.value;
            setSearchQuery(value);
            if (value === query) return;
            setIsLoadingResults(true);
          }}
          data-keyboard-nav
        />
        <GameFilterBar
          noAllGamesLink
          onSelectGame={(game) => {
            setFilter((prev) => {
              if (filter.game === game) return prev;
              setIsLoadingResults(true);
              return {
                ...prev,
                game: game || undefined,
              };
            });
          }}
          selectedGame={filter.game}
        />
      </SearchPageHeader>

      <ResultsWrapper
        // key={query} // handle edge case with scroll masking
        noResults={noResults}
        query={query}
        {...classNames(
          "mask-scroll",
          "results-content",
          allResultsFiltered && "all-results-filtered",
        )}
      >
        <div className="results-filters" ref={sidebarRef}>
          {availableFilters?.map(([f, values], i) => (
            <React.Fragment key={i}>
              <div
                {...classNames(
                  "group-label flex justify-between",
                  f.order && cssOrder(f.order),
                )}
              >
                <span>
                  {f.omitPrefix
                    ? t(...f.label)
                    : t("common:search.filterBy", "Filter by {{filter}}", {
                        filter: t(...f.label),
                      })}
                </span>
                {activeFilters.get(f) && (
                  <button
                    className="filter-clear"
                    onClick={() =>
                      setActiveFilters((prev) => {
                        const next = new Map(prev);
                        next.delete(f);
                        return next;
                      })
                    }
                  >
                    {t("common:search.clear", "Clear")}
                  </button>
                )}
              </div>
              {f.input && (
                <TextInput
                  placeholder={t(...f.input.placeholder)}
                  onChange={(e) => {
                    const v = e.currentTarget.value;
                    const injectedQuery = f.input.inject(
                      f.input.extract(query)[0],
                      v,
                    );
                    setSearchQuery(injectedQuery);
                    setIsLoadingResults(true);
                  }}
                  value={f.input.extract(query)[1] || ""}
                />
              )}
              {f.options && (
                <ol className="filter-options">
                  {values.map(([value, _count]) => {
                    const option = f.options[value];
                    const isActive = activeFilters.get(f)?.includes(value);
                    return (
                      <li
                        key={value}
                        onClick={(e) => {
                          if (isActive) return;
                          const value = e.currentTarget.dataset.value;
                          setActiveFilters((prev) => {
                            const next = new Map(prev);
                            const values = next.get(f) || [];
                            values.push(value);
                            next.set(f, values);
                            return next;
                          });
                        }}
                        data-value={value}
                        {...classNames(isActive && "active")}
                      >
                        {option.Icon && (
                          <div className="filter-option-icon">
                            <option.Icon />
                          </div>
                        )}
                        {option.icon}
                        {t(...option.label)}
                        {isActive && (
                          <button
                            onClick={(e) => {
                              const value = e.currentTarget.dataset.value;
                              setActiveFilters((prev) => {
                                const next = new Map(prev);
                                const values = next.get(f) || [];
                                removeFromArray(values, value);

                                if (values.length) next.set(f, values);
                                else next.delete(f);

                                return next;
                              });
                            }}
                            data-value={value}
                          >
                            <CircleClose />
                          </button>
                        )}
                      </li>
                    );
                  })}
                </ol>
              )}
              {f.Component && <f.Component />}
            </React.Fragment>
          ))}
          {isLoading && (
            <>
              {!resultsData.length && (
                <div className="group-label">
                  {t("search:searching", "Searching...")}
                </div>
              )}
              <div className="filter-options">
                {[...Array(resultsData.length ? 1 : 6)].map((_, i) => (
                  <div
                    key={i}
                    className="vary skeleton text-fixture"
                    style={varyStyle(query, i)}
                  />
                ))}
              </div>
            </>
          )}
        </div>
        <ResultsColumnWrapper
          query={query}
          allResultsFiltered={allResultsFiltered}
          handleClearFilters={handleClearFilters}
          className="results-groups"
        >
          {filteredResults.map((resultGroup) => (
            <ol className="result-group" key={resultGroup.label.toString()}>
              <h2 className="group-label">{t(...resultGroup.label)}</h2>
              {resultGroup.map((result) => {
                const game = result.game || result[CACHE_KEY]?.game;
                if (!game)
                  devWarn("search result is missing game", {
                    result,
                  });
                const gameLabel = GAME_NAME_MAP[game];
                const flair = [...(result.flair || [])];
                if (gameLabel)
                  flair.unshift({
                    trans: gameLabel,
                  });

                let img: React.ReactNode;
                if (result.img?.["raw"])
                  img = (
                    <div
                      className="img-svg"
                      dangerouslySetInnerHTML={{
                        __html: result.img["raw"],
                      }}
                    />
                  );
                else {
                  const imgSrc = result.img?.["fg"] || result.img;
                  if (imgSrc && typeof imgSrc === "string")
                    img ||= <img src={imgSrc} className={result.imageMode} />;
                }

                img ||= (
                  <div
                    className="round game-shape-icon"
                    style={{
                      backgroundColor: GAME_COLORS[game],
                    }}
                  >
                    {React.createElement(
                      GAME_ICON_SHAPES[game] || GAME_BOX_ICONS[game],
                    )}
                  </div>
                );

                return (
                  <li
                    key={`${result.url}:${result.key}:${
                      result.target || result.name
                    }`}
                    className="result-wrapper"
                  >
                    <a
                      href={result.url}
                      onClickCapture={() => {
                        // CAPTURE: running this during the capture phase allows
                        // us to prevent the browser from navigating
                        // before we can add it to the recent results
                        setIsNavigating(true);
                        addRecentResult(result);
                      }}
                      tabIndex={0}
                      data-keyboard-nav
                      {...classNames("result", result.static && "static")}
                    >
                      <div className="result-img-box">
                        {img}
                        <div
                          className="result-game-badge"
                          style={{ background: GAME_COLORS[game] }}
                        >
                          {React.createElement(GAME_BOX_ICONS[result.game])}
                        </div>
                      </div>
                      <div className="result-details">
                        <div className="name flex align-center gap-sp-2">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: result.snippet || result.name,
                            }}
                          />
                          {result.tagLine && (
                            <span className="tagline">{result.tagLine}</span>
                          )}
                          {result.shard && (
                            <div className="shard">
                              {renderText(t, result.shard)}
                            </div>
                          )}
                        </div>
                        {flair.length ? (
                          <div className="flair separate">
                            {flair.map((f, i) => (
                              <div
                                className="flex align-center gap-sp-1"
                                key={i}
                              >
                                {f.imageRaw && (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: f.imageRaw,
                                    }}
                                  />
                                )}
                                {f.trans && t(...f.trans)}
                                {f.text && f.text}
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </div>
                      <Ripple />
                    </a>
                    <div className="actions">
                      <button
                        className="copy-link hover-show"
                        onClick={(e) => {
                          const anchorEl =
                            e.currentTarget.parentElement.previousSibling;
                          if (!(anchorEl instanceof HTMLAnchorElement))
                            return devError(
                              "[search] copy: expected anchor element",
                            );
                          writeClipboard(anchorEl.href);
                          // gotta ripple on the main element wheeeeeeeeeeeeee
                          const rippleEl = anchorEl.lastChild;
                          if (!(rippleEl instanceof HTMLElement)) return;
                          rippleEl.style.setProperty("--ripple-scale", "0.45");
                          rippleEl.dispatchEvent(
                            new MouseEvent("pointerdown", {
                              bubbles: true,
                              clientX: e.clientX,
                              clientY: e.clientY,
                            }),
                          );
                        }}
                        data-tooltip={t(
                          "common:search.linkCopied",
                          "Link copied!",
                        )}
                        data-tip-size="sm"
                        data-tip-color="var(--shade2)"
                        data-tip-bg="transparent"
                        data-event="click"
                        data-place="left"
                      >
                        <Link />
                      </button>

                      {resultGroup.actions?.map((action, i) => (
                        <button
                          key={i}
                          onClick={(e) => action.handler(result, e)}
                        >
                          {React.createElement(action.Icon)}
                        </button>
                      ))}
                    </div>
                  </li>
                );
              })}
            </ol>
          ))}
          {isLoading && (
            <div className="group">
              <div className="group-label skeleton text-fixture vary" />
              {[...Array(resultsData.length ? 3 : 6)].map((_, i) => (
                <div key={i} className="result">
                  <div className="result-img-box skeleton" />
                  <div className="result-details">
                    <div className="flex align-center gap-sp-2">
                      <span
                        className="skeleton text-fixture vary"
                        style={varyStyle(query, i)}
                      />
                    </div>
                    <div
                      className="flair skeleton text-fixture vary"
                      style={varyStyle(query, 100 + 1)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </ResultsColumnWrapper>
      </ResultsWrapper>
    </SearchPageContainer>
  );
}

const ResultsWrapper = ({ noResults, query, ...restProps }) => {
  const { t } = useTranslation();

  if (!query && noResults) {
    return (
      <div className="no-results empty-cta">
        {t("search:pageEmptyCta", "Type to start searching!")}
      </div>
    );
  }

  if (noResults) {
    return (
      <div className="no-results">
        <Trans
          i18nKey={"search:noResultsDetailed.title"}
          defaults="No results for <span>{{query}}</span>"
          parent="h2"
          t={t}
          components={{
            span: <span />,
          }}
          values={{
            query,
          }}
        />
        <p>
          {t(
            "search:noResultsDetailed.desc",
            "Please make sure your query is spelled correctly",
            {
              query,
            },
          )}
        </p>
      </div>
    );
  }

  return <div {...restProps} />;
};

const ResultsColumnWrapper = ({
  allResultsFiltered,
  query,
  handleClearFilters,
  ...restProps
}) => {
  const { t } = useTranslation();
  if (allResultsFiltered)
    return (
      <div className="no-results">
        <Trans
          i18nKey={"search:allResultsFiltered.title"}
          defaults="No results with the current filters"
          parent="h2"
          t={t}
          components={{
            span: <span />,
          }}
          values={{
            query,
          }}
        />
        <Trans
          i18nKey={"search:allResultsFiltered.desc"}
          defaults="Please remove some filters or <button>clear all filters</button>"
          parent="p"
          t={t}
          components={{
            button: (
              <button className="cta-text" onClick={handleClearFilters} />
            ),
          }}
        />
      </div>
    );

  return <div {...restProps} />;
};

function getSearchInput() {
  return globals.document.getElementById("search-input");
}

function handleKeyboardNavigation(offset = 1) {
  const resultsArea = globals.document.querySelectorAll("[data-keyboard-nav]");

  let toFocus: HTMLElement;
  const results = Array.from(resultsArea);

  let activeElement = globals.document.activeElement;
  if (activeElement instanceof HTMLButtonElement)
    activeElement = activeElement.parentElement.firstElementChild;

  let idx = results.indexOf(activeElement);

  // we visually focus the first element, so pretend the first result was selected
  if (activeElement instanceof HTMLInputElement) {
    idx += 1;
  }

  if (idx === -1)
    toFocus = results[offset > 0 ? 0 : results.length - 1] as HTMLElement;
  else toFocus = results[idx + offset] as HTMLElement;

  toFocus?.focus({
    preventScroll: true,
  });
  toFocus?.scrollIntoView({
    block: "nearest",
    inline: "nearest",
    behavior: "smooth",
  });
}

const keyDownHandler = (e: KeyboardEvent) => {
  if (e.key === "ArrowDown") {
    e.preventDefault();
    e.stopPropagation();
    return handleKeyboardNavigation(1);
  }
  if (e.key === "ArrowUp") {
    e.preventDefault();
    e.stopPropagation();
    return handleKeyboardNavigation(-1);
  }

  if (e.key === "Enter") {
    if (globals.document.activeElement instanceof HTMLInputElement) {
      // go to the first result, i'm feeling lucky!
      const firstResult = globals.document.querySelector(
        "a[href][data-keyboard-nav]",
      );
      if (!(firstResult instanceof HTMLElement)) return;
      firstResult.focus();
      firstResult.click();

      e.preventDefault();
      e.stopPropagation();
    }
  }

  if (e.target instanceof HTMLInputElement) return;

  if (e.key.length === 1) getSearchInput()?.focus();
};

// generate offsets for skeleton (random lengths)
const decimalHash = (string) => {
  let sum = 0;
  for (let i = 0; i < string.length; i++)
    sum += ((i + 1) * string.codePointAt(i)) / (1 << 8);
  return sum % 1;
};

const varyStyle = (query: string, i: number) => {
  return {
    "--dec": String(decimalHash(`${query}${i * Math.PI}`)),
  } as React.CSSProperties;
};

const boostZero = (n: number) => {
  if (n === 0) return Math.pow(2, 24);
  return n;
};

export function meta() {
  return {
    title: ["search:pageTitle", "Search"],
    description: ["search:pageDescription", "Search for a term"],
  };
}

Object.assign(SearchPage, { fullBleed });

export default SearchPage;
