import React from "react";
import { css, keyframes, styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

const animFlash = () => keyframes`
    0% {
        background-position: 0%;
    }
    100% {
        background-position: -150%;
    }
`;

export const cssOrder = (order) => css`
  order: ${order};
`;

const fadeIn = () => keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const SearchPageHeader = styled("div")`
  position: sticky;
  top: var(--sp-4);
  width: 100%;
  max-width: var(--sp-container);

  padding-block-start: var(--page-padding);
  margin-block-start: calc(-1 * var(--page-padding));

  display: flex;
  flex-direction: column;
  gap: var(--sp-4);
  z-index: 2;

  padding-block-end: var(--sp-2);
`;

export const SearchPageContainer = styled("div", React.forwardRef)`
  width: 100%;
  flex: 1 1 auto;
  max-width: var(--sp-container);

  display: flex;
  flex-direction: column;
  gap: var(--sp-4);

  &.full-bleed {
    max-height: 100vh;
    gap: var(--sp-2);
    .results-content {
      min-height: 0px;
    }
    .results-groups,
    .results-filters {
      display: flex;
      flex-direction: column;
      padding-block-end: var(--page-end-value);
    }
  }

  &.is-navigating {
    /* pointer-events: none; */
    opacity: 0.9;
  }

  .results-filters {
    --content-start: calc(var(--sp-1) * 38);
    padding-inline: var(--sp-1) var(--sp-0_5);
    position: sticky;
    top: var(--y-offset);
    align-self: flex-start;
  }
  .results-groups {
    padding-inline: var(--sp-1) var(--sp-1);
  }
  .results-groups,
  .results-filters {
    display: flex;
    flex-direction: column;
    padding-block-end: var(--sp-2);
  }

  .hidden {
    visibility: hidden;
  }

  .text-fixture:empty::before {
    content: ".";
    color: transparent;
  }

  label {
    width: 100%;
    max-width: calc(var(--sp-1) * 125);
  }

  .results-content {
    position: relative;
    flex: 1;

    display: flex;
    gap: var(--sp-4);

    &.all-results-filtered {
      .results-filters-flex {
        display: none;
      }
    }
  }

  .mask-scroll {
    --scroll-top: 0px;
    mask-image: linear-gradient(
      to bottom,
      transparent var(--scroll-top),
      black calc(var(--scroll-top) + var(--sp-8))
    );
  }

  .results-filters {
    flex: 0 0 calc(var(--sp-1) * 60.5);
    padding-block-end: var(--page-padding);

    ${mobile} {
      display: none;
    }

    button.filter-clear {
      all: unset;
      cursor: pointer;
      margin-left: auto;

      color: var(--shade4);
      padding-inline: var(--sp-0_5);
      &:hover {
        color: var(--shade2);
      }
    }
  }

  ${mobile} {
    .results-filters {
      display: none;
    }
  }

  .hug {
    width: min-content;
  }

  .group-label {
    margin-block: var(--sp-6) var(--sp-3);

    animation: ${fadeIn} var(--transition);

    font-weight: 750;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    font-size: var(--sp-3e);
    color: var(--shade2);
    white-space: nowrap;
  }

  .filter-options {
    display: flex;
    flex-direction: column;
    gap: var(--sp-0_5);

    font-size: var(--sp-3_5e);
    font-weight: 600;

    & > * {
      --padding: var(--sp-2) var(--sp-3);

      &:not(.skeleton) {
        padding: var(--padding);
      }
      &.skeleton {
        margin: var(--padding);
      }

      display: flex;
      align-items: center;
      gap: var(--sp-3);

      animation: ${fadeIn} var(--transition);

      cursor: pointer;
      border-radius: var(--br);

      &:hover {
        background-color: var(--shade5-25);
        outline: 2px solid var(--shade2-25);
      }
      &.active {
        background-color: var(--shade7);
      }
      .filter-option-icon {
        width: var(--sp-6);
        height: var(--sp-6);
      }

      button {
        all: unset;
        margin-inline-start: auto;
        padding: var(--sp-0_5);

        border-radius: 50%;
        color: var(--shade2-50);
        &:hover {
          color: var(--shade2) !important;
        }
        svg {
          width: var(--sp-4_5);
          height: var(--sp-4_5);
        }
      }
    }
  }

  .results-groups {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: var(--sp-4);
  }

  .result-group {
    display: flex;
    flex-direction: column;
    gap: var(--sp-1);
    .group-label {
      margin-inline: var(--sp-3);
    }
  }

  .result-wrapper {
    position: relative;

    display: flex;
    align-items: center;

    &:not(:hover) .hover-show {
      visibility: hidden;
    }
  }
  .actions {
    position: absolute;
    right: 0;
    padding-inline-end: var(--sp-4);

    display: flex;
    align-items: center;
    gap: var(--sp-1);

    button {
      all: unset;
      cursor: pointer;
      padding: var(--sp-1_5);
      color: var(--shade2-50);
      border-radius: var(--br-sm);
      svg {
        width: var(--sp-4_5);
        height: var(--sp-4_5);
      }
      &:hover {
        color: var(--shade2);
      }
      &:focus {
        color: var(--shade1);
      }
    }
  }

  .result-wrapper {
    border-radius: var(--br-lg);
    &:hover:not(.skeleton),
    &:focus-within:not(.skeleton) {
      --result-bg: var(--shade7);
      background-color: var(--result-bg);
      outline-offset: 2px;
      outline: 2px solid var(--shade4);
    }
    a:focus {
      outline: none;
    }
  }
  .result {
    flex: 1;

    display: flex;
    align-items: center;
    gap: var(--sp-4);

    padding: var(--sp-2_5);

    animation: ${fadeIn} var(--transition);

    img {
      --size: var(--sp-12);
      width: var(--size);
      height: var(--size);

      &.showcase,
      &.full {
        width: var(--sp-24);
        object-fit: cover;
      }

      &.zoom-in {
        --zoom-scale: 1.25;
        background-size: cover;
        background-position: center;
        transform: scale(var(--zoom-scale));
        clip-path: inset(
          /* -1px because: this needs to round down. 0.5 does not work. */
            calc(var(--size) * (var(--zoom-scale) - 1) / 2 - 1px) round
            var(--br)
        );
      }

      border-radius: 50%;
    }

    .game-shape-icon {
      width: var(--sp-12);
      height: var(--sp-12);
      color: var(--shade10-75);
      svg {
        width: var(--sp-6);
        height: var(--sp-6);
      }
      background-color: var(--shade5-25);
      display: grid;
      place-items: center;
    }

    .result-img-box {
      --img-width: var(--sp-12);
      --img-height: var(--sp-12);
      position: relative;
      &.skeleton,
      .img-svg {
        width: var(--img-width);
        height: var(--img-height);
        border-radius: 50%;
      }
      .img-svg {
        background: var(--shade6);
        padding: var(--sp-2_5);
        display: grid;
        place-items: center;
        color: var(--shade0-75);
      }
    }
    .result-game-badge {
      color: var(--shade9);
      position: absolute;
      bottom: 0;
      right: calc(var(--sp-1) * -0.5);
      width: calc(var(--sp-1) * 5);
      height: calc(var(--sp-1) * 5);

      display: grid;
      place-items: center;
      border-radius: var(--br);
      box-shadow: 0 0 0 4px var(--app-bg);

      svg {
        width: 100%;
        height: 100%;
      }
    }
    &.static img {
      border-radius: var(--br-lg);
    }

    .result-details {
      flex: 1;

      display: flex;
      flex-direction: column;
      gap: var(--sp-1);

      font-weight: 700;
      mark {
        all: unset;
        font-weight: 500;
      }
      .name {
        height: var(--sp-5);
      }
      .flair {
        height: var(--sp-6);
      }
    }

    .tagline {
      color: var(--shade1);
      font-weight: 525;
    }

    .shard {
      background: var(--shade1-15);
      padding: var(--sp-1);
      line-height: 1;
      height: var(--sp-5);
      border-radius: var(--br-sm);
      font-size: var(--sp-3e);
      color: var(--shade1);
      font-weight: 600;
      letter-spacing: -0.009em;
    }
  }

  .flair {
    display: flex;
    align-items: center;
    gap: var(--sp-1);
    font-size: var(--sp-3_5e);
    font-weight: 600;
    color: var(--shade1);
    svg {
      width: var(--sp-4);
      height: var(--sp-4);
    }
  }

  .separate {
    display: flex;
    align-items: center;
    > *:not(:first-child)::before {
      content: "•";
      font-size: var(--sp-2);
      color: var(--shade4);
      margin-inline: var(--sp-1);
    }
  }

  .skeleton {
    --dec: 1;
    color: transparent;
    border-radius: var(--br-sm);

    &.result {
      height: var(--sp-18);
      border-radius: var(--br-lg);
      padding: 0;
      margin-block: var(--sp-1_5);
    }

    &.vary {
      width: calc((0.6 + var(--dec) * 0.4) * min(var(--sp-32), 100%));
      transition: width var(--transition-long);
    }

    --bg-color: var(--shade5-25);
    --flash-color: var(--shade2-25);

    background: linear-gradient(
      113.04deg,
      var(--bg-color) 0%,
      var(--bg-color) 25%,
      var(--flash-color) 45%,
      var(--flash-color) 55%,
      var(--bg-color) 75%,
      var(--bg-color) 100%
    );
    background-size: 300% 300%;

    animation: ${animFlash()} 1s linear infinite;
  }

  .no-results {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      color: var(--shade1);
      span {
        color: var(--shade0);
      }
    }
    p {
      color: var(--shade1);
    }

    animation: ${fadeIn()} 0.15s ease-in-out 0.05s both;
  }

  .empty-cta {
    font-size: var(--sp-4_5e);
    font-weight: 600;
  }

  .cta-text {
    background: none;
    padding: 0;
    color: var(--shade0);
    font-weight: 700;
    cursor: pointer;
  }

  &:has(input:focus) {
    .results-groups .result-group:first-of-type .result-wrapper:first-of-type {
      --result-bg: var(--shade7);
      background-color: var(--result-bg);
      outline-offset: 2px;
      outline: 2px solid var(--shade4);
    }
  }

  input {
    font-weight: 500;
  }
`;
